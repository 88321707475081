:where(.heading) {
  text-align: center;
}

:where(.heading-title) {
  font-size: 1.875rem;
  font-weight: bold;
  line-height: 3.5rem;
}

:where(.heading-subtitle) {
  padding-block-start: 1rem;
  padding-block-end: 2rem;
  color: hsl(var(--color-purple-600));
  line-height: 1.5rem;
  font-size: 0.875rem;
}

:where(.heading:empty) {
  display: none;
}

@media screen and (min-width: 48rem) {
  :where(.heading) {
    padding: 0 calc((100vw - var(--content-max-width)));
    padding-bottom: 1.5rem;
  }

  :where(.heading-title) {
    font-size: 2.75rem;
  }
}

@media screen and (min-width: 64rem) {
  :where(.heading) {
    padding: 0 19.25rem;
  }
}
