:where(.tag) {
  --size: 0.875rem;
  --color: var(--color-blue-violet-500);
  --transition: ease-in var(--transition-duration-bullet);

  font-size: var(--size);
  font-weight: 600;
  color: hsl(var(--color));
  border: 1px solid hsl(var(--color) / 50%);
  border-radius: 6.25rem;
  padding: 0.125rem 0.5rem;
  cursor: pointer;
  transition: background-color var(--transition), color var(--transition);
}

:where(.tag:hover, .tag:focus) {
  outline: none;
  background-color: hsl(var(--color));
  color: hsl(var(--background-color));
}
