:where(.project-tile) {
  display: grid;
  gap: 0.5rem;
  border-radius: var(--br-400);
  background-color: hsl(var(--background-color));
  padding-bottom: 1rem;
  height: 100%;
  align-content: start;
}

:where(.project-tile-title) {
  font-size: 1.5rem;
  line-height: 2rem;
  padding: 0 1rem;
  font-weight: 700;
}

:where(.cover-image) {
  background-color: hsl(var(--color-gray-100));
  aspect-ratio: var(--aspect-project-cover);
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
}

:where(.cover-image > img) {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

:where(.tag-list) {
  padding-inline: 1rem;
  max-height: 2.25rem;
  overflow: hidden;
}

.container {
  container-type: inline-size;
}

[data-color-scheme-dark] :where(.project-tile) {
  background-color: hsl(var(--color-gray-100) / 50%);
  backdrop-filter: blur(2rem);
}

@container (max-width: 21rem) {
  .cover-image {
    aspect-ratio: 8 / 5;
  }
}
