:where(.reference-tile) {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  border-radius: var(--br-400);
  background-color: hsl(var(--background-color));
  padding: 2rem;
  height: 23rem;
}

:where(.author-info) {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

:where(.author-info-button) {
  margin-top: auto;
}

:where(.author-image) {
  border-radius: var(--br-max);
}

:where(.author-info-address) {
  display: flex;
  flex-direction: column;
}

:where(.author-name) {
  font-size: 1.25rem;
  font-weight: bold;
}

:where(.author-position) {
  color: hsl(var(--color-purple-600));
  font-size: 0.875rem;
}

:where(.body) {
  color: hsl(var(--color-purple-600));
  font-size: 0.875rem;
  overflow: auto;
}

.body::-webkit-scrollbar {
  width: 0.375rem;
}

.body::-webkit-scrollbar-thumb {
  background-color: hsl(var(--color-gray-400) / 60%);
  border-radius: var(--br-400);
}

[data-color-scheme-dark] :where(.reference-tile) {
  background-color: hsl(var(--color-gray-100) / 50%);
  backdrop-filter: blur(2rem);
}
