.introduction {
  display: grid;
  gap: 0.75rem;
  position: relative;
  align-items: center;
  overflow: hidden;
  background-image: linear-gradient(
    120deg,
    hsl(var(--color-blue-400) / 20%) 30%,
    hsl(var(--color-pink-400) / 20%)
  );
}

.introduction::after {
  content: "";
  background-color: hsl(var(--color-pink-300));
  opacity: 0.5;
  filter: blur(40vw);
  width: 100vw;
  height: 100vw;
  border-radius: var(--br-max);
  position: absolute;
  top: 90vw;
  left: 10vw;
  z-index: 2;
}

.introduction::before {
  content: "";
  background-color: hsl(var(--color-blue-300));
  opacity: 0.5;
  filter: blur(33vw);
  width: 100vw;
  height: 100vw;
  position: absolute;
  top: 40vw;
  left: -25vw;
  z-index: 1;
}

.introduction-info {
  display: grid;
  gap: 0.75rem;
  z-index: 3;
}

.introduction-info-heading2 {
  color: hsl(var(--color-purple-600));
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  text-transform: uppercase;
}

.introduction-info-heading3 {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  line-height: 2.8125rem;
}

.introduction-info-heading3 span:nth-of-type(2) {
  color: hsl(var(--color-blue-500));
}

.introduction-info p {
  padding-bottom: 1rem;
}

.introduction-info-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.introduction-info-buttons a button {
  width: 100%;
}

.introduction-info-buttons-button {
  color: hsl(var(--color-blue-500));
  background-color: hsl(var(--background-color));
  width: 100%;
}

.introduction-info-buttons-button:hover {
  background-color: hsl(var(--background-color) / 60%);
}

.introduction-image {
  margin-bottom: 1rem;
  z-index: 3;
  object-fit: contain;
  width: 100%;
  height: 100%;
  user-select: none;
}

.introduction-background-logo {
  --t: auto;
  --l: auto;
  --r: none;
  --size: auto;

  position: absolute;
  user-select: none;
  top: var(--t);
  left: var(--l);
  width: var(--size);
  height: var(--size);
  rotate: var(--r);
}

.introduction-background-logo[data-index="0"] {
  --t: 39%;
  --l: 20%;
  --r: 0.25turn;
}

.introduction-background-logo[data-index="1"] {
  --t: 7.5%;
  --l: 11%;
  --r: 0.43turn;
  --size: 1.5rem;
}

.introduction-background-logo[data-index="2"] {
  --t: 3.5%;
  --l: 38%;
  --r: 0.18turn;
  --size: 3.5rem;
}

.introduction-background-logo[data-index="3"] {
  --t: 18%;
  --l: 80%;
  --r: 0.65turn;
}

.introduction-background-logo[data-index="4"] {
  --t: 39%;
  --l: 89%;
  --r: 0.75turn;
  --size: 3.4rem;
}

.introduction-background-logo[data-index="5"] {
  --t: 24%;
  --l: 6%;
  --r: -0.5turn;
}

.introduction-background-logo[data-index="6"] {
  --t: 80%;
  --l: 34%;
  --r: 0.12turn;
  --size: 5rem;
}

.what-do-we-offer {
  display: flex;
  flex-direction: column;
  scroll-margin-top: var(--header-height);
}

.what-do-we-offer-tiles {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: auto;
  gap: 0.75rem;
  padding: 0;
  padding-top: 1rem;
}

.projects {
  display: grid;
  align-content: center;
}

.projects-tiles {
  display: grid;
  gap: 2rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.projects-button-wrapper-button {
  margin-top: 1rem;
  width: 100%;
}

.references {
  background: linear-gradient(
    132.88deg,
    hsl(var(--color-blue-200)),
    hsl(var(--color-pink-100)),
    hsl(var(--background-color))
  );
}

.client-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
  justify-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog {
  display: grid;
  gap: 2rem;
  background: linear-gradient(
    132.88deg,
    hsl(var(--color-purple-200)),
    hsl(var(--color-blue-100) / 50%)
  );
}

.blog-list {
  display: grid;
  gap: 1.5rem;
  list-style: none;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 36rem) {
  .blog-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 48rem) {
  .introduction {
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }

  .introduction-image {
    margin-bottom: 3rem;
  }

  .introduction::after {
    filter: blur(40vw);
    opacity: 0.3;
    width: 75vw;
    height: 75vw;
    top: -40vw;
    left: 40vw;
  }

  .introduction::before {
    opacity: 0.3;
    filter: blur(17vw);
    width: 65vw;
    height: 65vw;
    top: -15vw;
    left: -15vw;
  }

  .introduction-info {
    align-content: flex-start;
  }

  .introduction-info-heading3 {
    font-size: 2.5rem;
    line-height: 3rem;
    padding: 0;
  }

  .introduction-info-buttons {
    flex-direction: row;
  }

  .introduction-info-buttons button {
    line-height: 1.429rem;
    font-size: 0.875rem;
    width: auto;
    padding: 0.875rem 1rem;
  }

  .introduction-background-logo1 {
    width: 3.6875rem;
    height: 3.4375rem;
    top: 8%;
    left: -1%;
  }

  .introduction-background-logo2 {
    width: 2.125rem;
    height: 2rem;
    top: 70%;
    left: 12%;
  }

  .introduction-background-logo3 {
    width: 3.6875rem;
    height: 3.4375rem;
    top: 72%;
  }

  .introduction-background-logo4 {
    width: 3.6875rem;
    height: 3.4375rem;
    top: 16%;
    left: 49.5%;
  }

  .introduction-background-logo5 {
    width: 2.125rem;
    height: 2rem;
    top: 6%;
    left: 88%;
  }

  .introduction-background-logo6 {
    width: 5.3125rem;
    height: 5rem;
    top: 70%;
    left: 86.5%;
  }

  .introduction-background-logo7 {
    display: block;
    position: absolute;
    z-index: 3;
    width: 2.125rem;
    height: 2rem;
    top: 85%;
    left: 68%;
  }

  .what-do-we-offer {
    padding: 6.5rem max(calc((100vw - var(--content-max-width)) / 2), 0.75rem);
  }

  .what-do-we-offer-tiles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    gap: 2rem;
    align-items: center;
  }

  .what-do-we-offer-tiles :nth-child(7) {
    display: none;
  }

  .projects-tiles {
    gap: 1.5rem;
    row-gap: 3rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .projects-button-wrapper {
    justify-self: center;
  }

  .projects-button-wrapper-button {
    justify-self: center;
    width: auto;
  }

  .client-list {
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 64rem) {
  .introduction {
    grid-template-columns: 0.75fr 1fr;
  }

  .introduction-image {
    aspect-ratio: auto;
    height: 656px;
    margin-bottom: 5rem;
    margin-right: -120px;
  }

  .introduction-info-heading3 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .what-do-we-offer {
    padding: 6.5rem max(calc((100vw - var(--content-max-width)) / 2), 0.75rem);
  }

  .what-do-we-offer-tiles {
    display: grid;
    position: relative;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 2rem;
    align-items: center;
  }

  .what-do-we-offer-tiles :nth-child(1) {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  .what-do-we-offer-tiles :nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  .what-do-we-offer-tiles :nth-child(3) {
    grid-column: 3;
    grid-row: 1 / span 2;
  }

  .what-do-we-offer-tiles :nth-child(4) {
    grid-column: 1;
    grid-row: 2 / span 2;
  }

  .what-do-we-offer-tiles :nth-child(5) {
    grid-column: 2;
    grid-row: 2;
  }

  .what-do-we-offer-tiles :nth-child(6) {
    grid-column: 3;
    grid-row: 2 / span 2;
  }

  .what-do-we-offer-tiles :nth-child(7) {
    grid-column: 2;
    grid-row: 3;
    display: flex;
  }
  .blog-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 80rem) {
  .blog-list {
    grid-template-columns: repeat(4, 1fr);
  }

  .introduction-info-heading3 {
    font-size: 3.5rem;
  }
}
