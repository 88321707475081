:where(.section) {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 1rem;
}

:where(.section[data-page-padding-top]) {
  padding-top: 6rem;
}

:where(.section[data-no-padding]) {
  padding: 0;
}

:where(.section[data-reduced-block-padding]) {
  padding-block: 1rem;
}

@media screen and (min-width: 48rem) {
  :where(.section) {
    padding-inline: var(--content-inline-padding);
  }

  :where(.section[data-extra-block-padding]) {
    padding-block: 6rem;
  }
}
