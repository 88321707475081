:where(.partner-tile) {
  padding: 1rem;
  border-radius: var(--br-400);
  overflow: hidden;
}

:where(.logo) {
  object-fit: contain;
}

[data-color-scheme-dark] :where(.logo) {
  filter: brightness(0) invert(1);
}
