:where(.carousel) {
  --dots-margin-block: 1rem;
}

:where(.carousel-dots) {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: var(--dots-margin-block) 0;
}

:where(.carousel-dot) {
  font-size: 1rem;
  background-color: transparent !important;
  border: 1px solid hsl(var(--color-blue-500)) !important;
  border-radius: 100% !important;
  width: 8px !important;
  height: 8px !important;
  padding: 0.125rem !important;

  cursor: pointer;
}

:where(.carousel-dot::before) {
  content: none !important;
}

:where(.carousel-dot[data-active="true"]) {
  background-color: hsl(var(--color-blue-500)) !important;
}

.carousel :is(:global(.slick-prev), :global(.slick-next)) {
  color: hsl(var(--color-gray-400));
  top: calc(50% - var(--dots-margin-block) * 2);
}

.carousel :global(.slick-prev) {
  left: -0.625rem;
  transform: scaleX(-1) translateY(-50%);
}

.carousel :global(.slick-next) {
  right: -0.625rem;
  transform: translateY(-50%);
}

.carousel :is(:global(.slick-next):hover, :global(.slick-prev):hover) {
  color: hsl(var(--color-blue-500));
}

.carousel :global(.slick-slide) {
  padding-inline: 1.5rem;
}
