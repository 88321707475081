:where(.modal[open]) {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  border: 0;
  width: 100%;
  height: 100%;
  padding: 2rem;
  margin: 0;
  background-color: hsl(var(--background-color));
  color: hsl(var(--foreground-color));
  border-radius: var(--br-400);
}

.modal::backdrop {
  background-color: hsl(202 100% 45% / 10%);
  backdrop-filter: blur(1.5rem);
}

:where(.header) {
  display: flex;
  position: relative;
  justify-content: space-between;
}

:where(.title) {
  font-size: 2rem;
  font-weight: 700;
}

:where(.close-button) {
  display: inline-flex;
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  line-height: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5em;
  right: 0;
}

[data-color-scheme-dark] .modal::backdrop {
  background-color: hsl(0 0% 0% / 30%);
}

@media screen and (min-width: 40rem) {
  :where(.modal[open]) {
    max-width: 36.5rem;
    height: fit-content;
  }

  :where(.close-button) {
    align-items: center;
  }
}
