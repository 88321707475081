:where(.statistics) {
  display: grid;
  position: relative;
  gap: 1rem 2rem;
  padding: 0 max(calc((100vw - var(--content-max-width)) / 2), 0.75rem);
  margin-top: -4rem;
  z-index: 4;
}

:where(.statistic-tile) {
  background-color: hsl(var(--background-color));
  border-radius: var(--br-400);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 1.5rem 0 1.75rem;
  line-height: 1.5rem;
  font-size: 1rem;
  box-shadow: 0 0.75rem 1rem #00000014;
  width: 100%;
  margin: auto;
}

:where(.statistic-tile span) {
  font-size: 2.5rem;
  line-height: 3.125rem;
  font-weight: bold;
  color: hsl(var(--color-blue-500));
}

@media screen and (min-width: 48rem) {
  :where(.statistics) {
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(10rem, calc(25% - 1.5rem)));
  }
}

[data-color-scheme-dark] :where(.statistic-tile) {
  background-color: hsl(var(--color-gray-100) / 50%);
  backdrop-filter: blur(2rem);
}
