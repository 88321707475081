:where(.service-tile) {
  position: relative;
  border-radius: var(--br-400);
  background-color: hsl(var(--background-color));
}

:where(.service-tile-figure) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  flex: 0 0 100%;
  height: auto;
  width: 17.25rem;
  min-height: 22rem;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: var(--br-400);
  background-color: hsl(var(--color-white) / 50%);
  position: relative;
  z-index: 2;
}

:where(.service-tile-background) {
  --gradient-opacity: 0%;
  --transition: ease-in-out var(--transition-duration-normal);

  position: absolute;
  inset: 0;
  background-image: radial-gradient(
    ellipse at bottom,
    hsl(var(--color-pink-400) / var(--gradient-opacity)),
    hsl(var(--color-blue-100) / var(--gradient-opacity))
  );
  border-radius: var(--br-400);
  z-index: 1;
  transition: background-image var(--transition);
}

:where(.service-tile:hover .service-tile-background) {
  --gradient-opacity: 10%;
}

:where(.title) {
  font-size: 1.25rem;
  font-weight: 700;
}

:where(.caption) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

:where(.tag-list) {
  justify-content: center;
}

@media screen and (min-width: 48rem) {
  :where(.button) {
    display: none;
  }
}

[data-color-scheme-dark] :where(.service-tile-figure) {
  background-color: hsl(var(--color-gray-100) / 50%);
}

[data-color-scheme-dark] :where(.service-tile-background) {
  --gradient-opacity: 10%;
}

[data-color-scheme-dark] :where(.service-tile:hover .service-tile-background) {
  --gradient-opacity: 20%;
}

[data-color-scheme-dark] :where(.icon) {
  filter: brightness(0.95);
}
