:where(.blog-tile) {
  display: grid;
  align-content: flex-start;
  border-radius: var(--br-400);
  height: 100%;
  background-color: hsl(var(--color-white));
}

:where(.blog-tile-footer) {
  display: grid;
  gap: 0.5rem;
  padding: 0.75rem;
}

:where(.blog-tile-date) {
  font-size: 0.875rem;
}

:where(.blog-tile-title) {
  font-weight: 600;
  font-size: 1.125rem;
}

:where(.blog-tile-tag-list) {
  justify-content: flex-start;
}

:where(.blog-tile-image) {
  min-width: 280px;
  border-top-right-radius: var(--br-400);
  border-top-left-radius: var(--br-400);
  overflow: hidden;
  background-color: hsl(var(--color-gray-100));
}

:where(.blog-tile-image > img) {
  aspect-ratio: var(--aspect-article-cover);
  width: 100%;
  height: 100%;
}

[data-color-scheme-dark] :where(.blog-tile) {
  background-color: hsl(var(--color-gray-100) / 50%);
  backdrop-filter: blur(2rem);
}
