:where(.tag-list) {
  display: flex;
  gap: 0.25rem;
  list-style: none;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
}

:where(.tag-list > li) {
  display: inline-flex;
  align-self: center;
}
