@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.work-step-tiles .icon {
  order: 1;
}

.work-step-tile .content {
  order: 2;
}

:where(.work-step-tile) {
  --icon-size: 4.5em;

  display: grid;
  grid-template-columns: var(--icon-size) 1fr;
  gap: 2em;
  align-items: center;
  max-height: var(--icon-size);
}

:where(.work-step-tile[data-open="true"]) {
  max-height: 100rem;
  transition: max-height ease-in-out var(--transition-duration-sloth);
}

:where(.content[open] .content-description) {
  animation: fade-in ease-in-out var(--transition-duration-sloth) forwards;
}

:where(.content[open], .content[open] ~ .icon) {
  align-self: flex-start;
}

:where(.icon) {
  --svg-size: calc(var(--icon-size) + 0.25rem);
  display: grid;
  place-content: center;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: var(--br-600);
  overflow: hidden;
  transition: transform var(--transition-duration-arrow) ease-in-out;
  cursor: pointer;
}

.icon > svg {
  width: var(--svg-size);
  height: var(--svg-size);
}

:where(.content) {
  display: grid;
  gap: 0.5em;
}

:where(.content-summary) {
  font-size: 1.25rem;
  font-weight: 700;
  list-style: none;
  cursor: pointer;
}

.content-summary::-webkit-details-marker {
  font-size: 0;
}

:where(.content-description) {
  color: hsl(var(--color-dark-gunmetal-600));
  font-weight: 300;
  white-space: pre-line;
}

@media screen and (min-width: 48rem) {
  .work-step-tile[data-icon-position="end"] {
    grid-template-columns: 1fr var(--icon-size);
  }

  .work-step-tile[data-icon-position="end"] .icon {
    order: 2;
  }

  .work-step-tile[data-icon-position="end"] .content {
    order: 1;
  }

  .work-step-tile[data-icon-position="end"] .content-summary,
  .work-step-tile[data-icon-position="end"] :where(.content-description) {
    text-align: right;
  }

  :where(.work-step-tile:hover .icon) {
    transform: scale(1.15);
  }
}
