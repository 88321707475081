:where(.container) {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;
}

:where(.title) {
  font-size: 2rem;
  font-weight: 700;
}

:where(.content) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
  text-align: left;
  margin-top: 1rem;
  white-space: pre-line;
}

:where(.project-button) {
  margin-left: auto;
  margin-top: 1rem;
}

:where(.project-button:focus) {
  outline: none;
}

:where(.tag-list) {
  justify-content: flex-start;
}

@media screen and (min-width: 40rem) {
  :where(.container) {
    grid-template-areas: "icon title" "icon tags" "content content" ". button";
    grid-template-columns: 5.5rem auto;
  }

  :where(.title) {
    grid-area: title;
  }

  :where(.content) {
    grid-area: content;
  }

  :where(.service-icon) {
    grid-area: icon;
  }

  :where(.project-button) {
    grid-area: button;
  }

  :where(.tag-list) {
    grid-area: tags;
  }
}
