:where(.work-steps-section) {
  display: grid;
  background: linear-gradient(
    to left top,
    hsl(var(--color-blue-100)),
    hsl(var(--color-pink-200) / 20%)
  );
}

:where(.tiles) {
  --gap: 5rem;
  --gradient-height: 5rem;
  --icon-size: 4.5rem;

  display: grid;
  gap: var(--gap);
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}

:where(.tiles-item) {
  --border-offset: 0.35rem;
  --border-style: 1px dashed hsl(var(--color-blue-300));
  position: relative;
  z-index: 1;
}

:where(.tiles-item)::after,
:where(.tiles-item)::before {
  z-index: -1;
}

:where(.tiles-item):not(:last-child)::after {
  content: "";
  position: absolute;
  left: calc(var(--icon-size) / 2);
  top: calc(var(--icon-size) + var(--border-offset));
  height: calc(100% - var(--icon-size) - var(--border-offset) + 0.25rem);
  border-left: var(--border-style);
}

:where(.tiles-item):not(:first-child)::before {
  content: "";
  position: absolute;
  bottom: calc(100% + var(--border-offset));
  left: calc(var(--icon-size) / 2);
  height: calc(var(--gap) - var(--border-offset) * 2);
  border-left: var(--border-style);
}

@media screen and (min-width: 48rem) {
  .tiles {
    --gradient-height: 7rem;
    grid-template-columns: 1fr var(--icon-size) 1fr;
    gap: var(--gap) 0;
  }

  .tiles-item:nth-child(2n + 1) {
    grid-column: 1/3;
  }

  .tiles-item:nth-child(2n + 1):not(:last-child)::after,
  .tiles-item:nth-child(2n + 1)::before {
    left: unset;
    right: calc(var(--icon-size) / 2 - 1px);
  }

  .tiles-item:nth-child(2n) {
    grid-column: 2/5;
  }
}
